<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    
  },
  created(){	//vue生命周期创造时执行
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端 
		// var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端 
    // console.log('是否是Android：'+isAndroid);
		// console.log('是否是iOS：'+isiOS);
    // if()
		
    // /**兼容手机端1x、2x图样式*/
    // var html = document.documentElement;
    // html.style.fontSize= 100*(html.clientWidth / 750 )+'px';
    // document.body.style.fontSize = '16px';
    // /**兼容手机端1x、2x图样式*/
    var docEl = document.documentElement,
      resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
      recalc = function() {
        var clientWidth = docEl.clientWidth
        if (!clientWidth) return
        let Zwidht=Math.floor(100 * (clientWidth / 750));
        if(Zwidht>175){
          Zwidht=175
        }
        if(isAndroid){
          if(Zwidht<100){
            Zwidht=100
          }
        }
        docEl.style.fontSize = Zwidht + 'px'
      }
    if (!document.addEventListener) return
    window.addEventListener(resizeEvt, recalc, false)
    document.addEventListener('DOMContentLoaded', recalc, false)
  },
  methods:{
    htmlFontSize(){
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var width = w > h ? h : w;
        width = width > 720 ? 720 : width
        var fz = ~~(width*100000/36)/10000
        document.getElementsByTagName("html")[0].style.cssText = 'font-size: ' + fz +"px";
        var realfz = ~~(+window.getComputedStyle(document.getElementsByTagName("html")[0]).fontSize.replace('px','')*10000)/10000
        if (fz !== realfz) {
          document.getElementsByTagName("html")[0].style.cssText = 'font-size: ' + fz * (fz / realfz) +"px";
        }
    }
  }
}
</script>

<style>

</style>
