import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: "/homePage"
  },
  {
    path: "/homePage",
    name: "homePage",
    component: () => import("@/views/homePage.vue"),
    meta: { title: "首页" }
  },
];

const router = new VueRouter({
  routes,
});
// 设置路由守卫 to前往路由信息，from要离开的路由信息
// next参数是一个方法 有三种next()继续执行跳转，next('/login')跳转指定路由，next(false)停止跳转
// router.beforeEach((to, from, next) => {
//   let toName = to.name
//   // 校验白名单 可以把白名单的name放这里进行判断
//   let notPermission = ['loginPage', 'homePage']
//   // 判断白名单中是否有这个name有的话就直接跳
//   if (notPermission.indexOf(toName) !== -1) {
//     next()
//   } else {
//     // 判断路由中token是否存在，存在就继续，不存在跳转到登录页。
//     //一定要判断要跳转的页面不能是next跳的页面 会造成死循环的
//     if (localStorage.getItem('token') || toName === 'login') {
//       next()
//     }else{
//       next('/login')
//     }
//   }
// })
export default router